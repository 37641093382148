<template>
  <section class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-lg-8 col-md-11 col-12">
        <h1>{{ supplier.name }}</h1>
        <p>{{ supplier.cvr }}</p>
      </div>
    </div>

    <NavigationSlide
      :nav="[
        {
          name: 'SupplierInfo',
          params: '{ SupplierId: ' + id + '}',
          text: $t('supplierBase.info'),
          icon: 'building',
        },
        {
          name: 'SupplierContacts',
          params: '{ SupplierId: ' + id + '}',
          text: $t('supplierBase.contacts'),
          icon: 'address-card',
        },
        {
          name: 'SupplierPriceGroup',
          params: '{ SupplierId: ' + id + '}',
          text: $t('supplierBase.priceGroup'),
          icon: 'dollar-sign',
        },
        {
          name: 'SupplierPension',
          params: '{ SupplierId: ' + id + '}',
          text: $t('supplierBase.pension'),
          icon: 'list-alt',
        },
        {
          name: 'SupplierInsurance',
          params: '{ SupplierId: ' + id + '}',
          text: $t('supplierBase.insurance'),
          icon: 'house-damage',
        },
        {
          name: 'SupplierLogs',
          params: '{ SupplierId: ' + id + '}',
          text: $t('supplierBase.logs'),
          icon: 'server',
        },
        {
          name: 'SupplierSettings',
          params:
            '{ SupplierId: ' + id+'}',
          text: $t('supplierBase.settings'),
          icon: 'cog',
          hidden: !$can('update', 'Supplier','Settings'),
        },
      ]"
    />

    <div class="row">
      <div class="col">
        <router-view />
      </div>
    </div>
  </section>
</template>

<script>
import NavigationSlide from "@/modules/global/components/NavigationSlide.vue";
export default {
  name: "SupplierBase",
  components: {
    NavigationSlide,
  },
  async mounted() {
    await this.$store.dispatch("supplierVuex/getSupplier", this.id);
  },
  computed: {
    id() {
      return this.$route.params.supplierId;
    },
    supplier() {
      return this.$store.getters["supplierVuex/supplier"];
    },
  },
};
</script>

<style>
</style>