var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-lg-8 col-md-11 col-12"},[_c('h1',[_vm._v(_vm._s(_vm.supplier.name))]),_c('p',[_vm._v(_vm._s(_vm.supplier.cvr))])])]),_c('NavigationSlide',{attrs:{"nav":[
      {
        name: 'SupplierInfo',
        params: '{ SupplierId: ' + _vm.id + '}',
        text: _vm.$t('supplierBase.info'),
        icon: 'building',
      },
      {
        name: 'SupplierContacts',
        params: '{ SupplierId: ' + _vm.id + '}',
        text: _vm.$t('supplierBase.contacts'),
        icon: 'address-card',
      },
      {
        name: 'SupplierPriceGroup',
        params: '{ SupplierId: ' + _vm.id + '}',
        text: _vm.$t('supplierBase.priceGroup'),
        icon: 'dollar-sign',
      },
      {
        name: 'SupplierPension',
        params: '{ SupplierId: ' + _vm.id + '}',
        text: _vm.$t('supplierBase.pension'),
        icon: 'list-alt',
      },
      {
        name: 'SupplierInsurance',
        params: '{ SupplierId: ' + _vm.id + '}',
        text: _vm.$t('supplierBase.insurance'),
        icon: 'house-damage',
      },
      {
        name: 'SupplierLogs',
        params: '{ SupplierId: ' + _vm.id + '}',
        text: _vm.$t('supplierBase.logs'),
        icon: 'server',
      },
      {
        name: 'SupplierSettings',
        params:
          '{ SupplierId: ' + _vm.id+'}',
        text: _vm.$t('supplierBase.settings'),
        icon: 'cog',
        hidden: !_vm.$can('update', 'Supplier','Settings'),
      } ]}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('router-view')],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }